import { template as template_54d260997cb24b3b86dbd785fce59402 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_54d260997cb24b3b86dbd785fce59402(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
