import { template as template_295080b862ff43c29bd08f1f93b661d4 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_295080b862ff43c29bd08f1f93b661d4(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
